import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, updateDoc, doc, addDoc, query, where, getDocs, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, auth, storage } from "../firebase";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imageCompression from 'browser-image-compression';
import BeatLoader from 'react-spinners/esm/BeatLoader';
import PacmanLoader from 'react-spinners/esm/PacmanLoader';
import { DarkModeContext } from '../context/darkModeContext';
import { MdVerified } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import { useUserData } from '../lib/userData';
import ReactPlayer from 'react-player';

const genresArray = [
  "Action",
  "Adventure",
  "Animation",
  "Anime",
  "Biographical",
  "Black Comedy",
  "Comedy",
  "Crime",
  "Cult",
  "Cyberpunk",
  "Disaster",
  "Documentary",
  "Drama",
  "Experimental",
  "Family",
  "Fantasy",
  "Film Noir",
  "Foreign",
  "Heist",
  "Historical",
  "Holiday",
  "Horror",
  "Independent",
  "LGBTQ+",
  "Martial Arts",
  "Mockumentary",
  "Musical",
  "Mystery",
  "Political",
  "Psychological",
  "Religion",
  "Road",
  "Romance",
  "Romantic Comedy",
  "Satire",
  "Sci-Fi & Fantasy",
  "Sex",
  "Slasher",
  "Space Opera",
  "Sports",
  "Spy",
  "Superhero",
  "Supernatural",
  "Suspense",
  "Teen",
  "Teen Comedy",
  "Thriller",
  "Urban",
  "Vampire",
  "War",
  "Western",
  "Witches/Wizards",
  "Zombie"
];

const filmTypes = [
  {
    id: 1,
    name: "Full Movie",
  },
  {
    id: 2,
    name: "Upload Parts",
  }
];

const seasonCountries = [
  {
    id: 1,
    name: "American",
  },
  {
    id: 2,
    name: "Chinese",
  },
  {
    id: 3,
    name: "Indian",
  },
  {
    id: 3,
    name: "Indonesian",
  },
  {
    id: 5,
    name: "Japanese",
  },
  {
    id: 6,
    name: "Korean",
  },
  {
    id: 7,
    name: "Nigerian",
  },
  {
    id: 8,
    name: "Philipines",
  },
  {
    id: 9,
    name: "Thailand",
  },
  {
    id: 10,
    name: "Turkish",
  },
  {
    id: 11,
    name: "Other",
  }
];

const CreateRelease = () => {
  const navigate = useNavigate();
  const userData = useUserData();
  const { darkMode } = useContext(DarkModeContext);
  const [movieTitle, setMovieTitle] = useState('');
  const [movieType, setMovieType] = useState('Single Film');
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [duration, setDuration] = useState('');
  const [movieCover, setMovieCover] = useState(null);
  const [movieBanner, setMovieBanner] = useState(null);
  const [trailer, setTrailer] = useState('');
  const [trailerType, setTrailerType] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [episodes, setEpisodes] = useState([{ title: '', downloadLink: '' }]);
  const [producers, setProducers] = useState([]);
  const [producerName, setProducerName] = useState('');
  const [producerVerificationStatus, setProducerVerificationStatus] = useState('');
  const [producerImage, setProducerImage] = useState(null);
  const [distributor, setDistributor] = useState('');
  const [selectedProducer, setSelectedProducer] = useState('');
  const [selectedFilm, setSelectedFilm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isFinished, setIsFinished] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [bannerProgress, setBannerProgress] = useState(null);
  const [trailerSource, setTrailerSource] = useState('');
  const [trailerURL, setTrailerURL] = useState('');
  const [trailerProgress, setTrailerProgress] = useState(null);
  const [description, setDescription] = useState('');
  const [movieParts, setMovieParts] = useState([]);

  useEffect(() => {
    const fetchProducers = async () => {
      try {
        const producersQuery = query(collection(db, 'users'), where('status', '==', 'producer'));
        const querySnapshot = await getDocs(producersQuery);
        const producersData = [];
        querySnapshot.forEach((doc) => {
          // Assuming 'displayName' is the field containing the name of the producer
          producersData.push({ id: doc.id, name: doc.data().displayName, verification: doc.data().verification, img: doc.data().img });
        });
        setProducers(producersData);
        console.log(producersData)
      } catch (error) {
        console.error('Error fetching producers:', error);
      }
    };

    fetchProducers();
  }, []);

  useEffect(() => {
    const isSingleFilmValid = movieType === 'Single Film' && (
      (selectedFilm === 'Full Movie' && downloadLink) ||
      (selectedFilm === 'Upload Parts' && movieParts.length > 0 && movieParts.every(part => part.title && part.downloadLink))
    );
  
    const isSeasonValid = movieType === 'Season' && episodes.length > 0 && episodes.every(episode => episode.title && episode.downloadLink);
  
    if (
      movieTitle &&
      selectedGenres.length > 0 &&
      movieCover &&
      (isSingleFilmValid || isSeasonValid)
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [movieTitle, selectedGenres, duration, movieCover, movieType, selectedFilm, movieParts, episodes]);

  const handleMovieCoverUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    if (!file.type.startsWith('image/')) {
      console.error('Selected file is not an image');
      return;
    }

    if (!movieTitle) {
      console.error('Please enter the movie title before uploading the cover image');
      return;
    }

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const previewURL = URL.createObjectURL(compressedFile); // Create preview URL
      setMovieCover(previewURL); // Update movie cover state with preview URL

      // Now, proceed with uploading the compressed file
      const fileName = `${movieTitle}_${Date.now()}_${compressedFile.name}`;
      const storageRef = ref(storage, `coverImages/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, compressedFile);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          if (progress === 100) {
            setSubmitDisabled(false);
          }
        },
        (error) => {
          toast.error('Failed to upload movie cover');
          console.error('Error uploading movie cover:', error.message);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log(downloadURL);
            // Set submit button enabled after successful image upload
            setSubmitDisabled(false);
            setMovieCover(downloadURL);
            toast.success('Movie cover uploaded successfully');
          } catch (error) {
            toast.error('Failed to create movie release');
            console.error('Error creating movie release:', error.message);
          }
        }
      );
    } catch (error) {
      console.error('Error compressing image:', error.message);
    }
  };

  const handleMovieBannerUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    if (!file.type.startsWith('image/')) {
      console.error('Selected file is not an image');
      return;
    }

    if (!movieTitle) {
      console.error('Please enter the movie title before uploading the cover image');
      return;
    }

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const previewURL = URL.createObjectURL(compressedFile); // Create preview URL
      setMovieBanner(previewURL); // Update movie cover state with preview URL

      // Now, proceed with uploading the compressed file
      const fileName = `${movieTitle}_${Date.now()}_${compressedFile.name}`;
      const storageRef = ref(storage, `coverImages/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, compressedFile);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const bannerProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setBannerProgress(bannerProgress);
          if (bannerProgress === 100) {
            setSubmitDisabled(false);
          }
        },
        (error) => {
          toast.error('Failed to upload movie banner');
          console.error('Error uploading movie banner:', error.message);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log(downloadURL);
            // Set submit button enabled after successful image upload
            setSubmitDisabled(false);
            setMovieBanner(downloadURL);
            toast.success('Movie banner uploaded successfully');
          } catch (error) {
            toast.error('Failed to create movie release');
            console.error('Error creating movie release:', error.message);
          }
        }
      );
    } catch (error) {
      console.error('Error compressing banner image:', error.message);
    }
  };

  // Function to handle trailer video upload
  const handleTrailerUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    if (!file.type.startsWith('video/')) {
      console.error('Selected file is not a video');
      return;
    }

    setTrailerType('file');

    try {
      const fileName = `${movieTitle}_${Date.now()}_${file.name}`;
      const storageRef = ref(storage, `trailers/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const trailerProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setTrailerProgress(trailerProgress);
          if (trailerProgress === 100) {
            setSubmitDisabled(false);
          }
        },
        (error) => {
          toast.error('Failed to upload trailer video');
          console.error('Error uploading trailer video:', error.message);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log(downloadURL);
            setTrailer(downloadURL);
            toast.success('Trailer video uploaded successfully');
          } catch (error) {
            toast.error('Failed to upload trailer video');
            console.error('Error uploading trailer video:', error.message);
          }
        }
      );
    } catch (error) {
      console.error('Error uploading trailer video:', error.message);
      toast.error('Failed to upload trailer video');
    }
  };

  const handleTrailerURLChange = (e) => {
    const url = e.target.value;
    setTrailerType('url');
    setTrailer(url);
  };

  useEffect(() => {
    if (trailerType === 'url' && trailer) {
      // Perform actions when the trailer URL changes
      setTrailer(trailer);
    }
  }, [trailer, trailerType]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const movieRef = await addDoc(collection(db, "movies"), {
        origin: 'movies',
        MovieTitle: movieTitle,
        Duration: duration,
        CoverImage: movieCover || '',
        BannerImage: movieBanner || '',
        Trailer: trailer || '',
        Genres: selectedGenres,
        Description: description,
        DownloadLink: downloadLink,
        ProducerID: userData?.id,
        ProducerName: userData?.displayName,
        ProducerVerificationStatus: userData?.verification,
        Type: movieType,
        filmType: movieType === 'Single Film' ? selectedFilm : null,
        Parts: movieType === 'Single Film' ? movieParts : [],
        movieCountry: movieType === 'Season' ? selectedCountry : null,
        Episodes: movieType === 'Season' ? episodes : [],
        Finish: movieType === 'Season' ? isFinished : null,
        timeStamp: serverTimestamp()
      });

      // Assign the newly created document ID to the 'id' field
      const docId = movieRef.id;

      await updateDoc(movieRef, {
        id: docId,
        ProducerImageUrl: userData?.img
      });

      toast.success('Movie release created successfully');

      // Navigate back to '/create' after a successful movie release
      navigate('/create');

      setMovieTitle('');
      setSelectedGenres([]);
      setDescription('');
      setDuration('');
      setProducerName(userData?.displayName);
      setProducerVerificationStatus(userData?.verification);
      setDistributor('');
      setMovieCover(null);
      setMovieBanner(null);
      setTrailer(null);
      setProducerImage(userData?.img);
      setDownloadLink('');
      setSelectedCountry('');
      setIsFinished(null);
      setMovieParts([{ title: '', downloadLink: '' }]);
      setEpisodes([{ title: '', downloadLink: '' }]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Failed to create movie release');
      console.error('Error creating movie release:', error.message);
    }
  };

  const handleSelectFilm = (e) => {
    setSelectedFilm(e.target.value);
  };

  const handleSelectCountry = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleCheckboxChange = () => {
    setIsFinished(!isFinished);
  };

  const handleAddEpisode = () => {
    setEpisodes([...episodes, { title: '', downloadLink: '' }]);
  };

  const handleRemoveEpisode = (indexToRemove) => {
    setEpisodes(episodes.filter((_, index) => index !== indexToRemove));
  };

  const handleEpisodeChange = (index, key, value) => {
    const updatedEpisodes = [...episodes];
    updatedEpisodes[index][key] = value;
    setEpisodes(updatedEpisodes);
  };

  const handlePartChange = (index, key, value) => {
    const updatedParts = [...movieParts];
    updatedParts[index][key] = value;
    setMovieParts(updatedParts);
  };

  const addPart = () => {
    setMovieParts([...movieParts, { title: '', downloadLink: '' }]);
  };

  const handleRemovePart = (indexToRemove) => {
    setMovieParts(movieParts.filter((_, index) => index !== indexToRemove));
  };

  const handleCancel = () => {
    navigate('/create');
  };

  return (
    <div>
      <div>
        <div className='pb-2 flex justify-between'>
          <div className=''>Preview</div>
          <div className='font-bold text-zinc-400'>New Release</div>
        </div>
        <div className={`rounded-2xl shadow-xl h-[200px] flex justify-between p-5 px-10 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white"}`}>
          <div className='flex justify-between gap-5'>
            <img
              src={movieCover instanceof File ? URL.createObjectURL(movieCover) : movieCover}
              alt='Movie Cover Page'
              title='Movie Cover Page'
              className='bg-zinc-400 w-[120px] rounded-2xl'
            />
            <div className=''>
              <div className='font-bold text-[18px] pb-1'>{movieTitle || "Movie Title"}</div>
              <div className='text-[14px] text-slate-600 font-medium'>{movieType}</div>
              {movieType === 'Season' && (
                <div className='text-[14px]'>Episodes: {episodes?.length}</div>
              )}
              {selectedGenres.length > 0 ? (
                <>
                  {selectedGenres.map((genre, index) => (
                    <div key={index} className='text-[14px]'>{genre}</div>
                  ))}
                </>
              ) : (
                <div className='text-[14px]'>Genre</div>
              )}
              {movieType === 'Single Film' && (
                <div className='text-[14px]'>Duration: {duration || "00:00:00"}</div>
              )}
            </div>
          </div>
          <div className='flex justify-between gap-8'>
            <div>
              {trailer ? (
                <>
                  <ReactPlayer
                    light={true}
                    controls={true}
                    url={trailer}
                    height="140px"
                    width="260px"
                    className='border-2'
                  />
                  <div className='text-[12px] text-slate-400 font-semibold text-center'>Trailer</div>
                </>
              ) : (null)}
            </div>
            <div className='w-[2px] bg-gray-200 h-full'></div>
            <div className='items-center flex flex-col flex-shrink-0'>
              <img
                src={userData?.img || ''}
                alt='Producer Image'
                title='Producer Image'
                className='h-[80px] w-[80px] m-2 shrink-0 rounded-full bg-zinc-100'
              />
              <div className='pt-2'>
                <div className='gap-1 flex justify-center items-center'>
                  <div className='text-[13px] font-semibold text-center'>{userData?.displayName || "DJ XXX"}</div>
                  {userData?.verification === "verified" && (
                    <div className='text-[13px] font-semibold text-center'>{darkMode ? <MdOutlineVerified /> : <MdVerified color='blue' />}</div>
                  )}
                </div>
                <div className='text-[12px] font-semibold text-center text-gray-300'>Producer</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='py-4 justify-between flex'>
          <div className='font-bold text-[20px] text-zinc-300'>Upload Movie</div>
          <div className='flex gap-4'>
            <div
              className={`font-bold cursor-pointer ${movieType === 'Single Film' ? 'text-green-400 text-[16px]' : 'text-zinc-400 text-[14px]'}`}
              onClick={() => setMovieType('Single Film')}
            >
              Single Film
            </div>
            <div className='font-bold text-zinc-400'>|</div>
            <div
              className={`font-bold cursor-pointer ${movieType === 'Season' ? 'text-green-400 text-[16px]' : 'text-zinc-400 text-[14px]'}`}
              onClick={() => setMovieType('Season')}
            >
              Season
            </div>
          </div>
          <button
            variant="contained"
            color="primary"
            disabled={submitDisabled}
            onClick={handleSubmit}
            className={`font-bold text-[20px] ${submitDisabled ? 'text-gray-500 cursor-not-allowed' : 'text-blue-600'}`}
          >
            {loading ?
              <div className='flex gap-2'>
                <PacmanLoader color="#3693d6" />
                <BeatLoader color="#3693d6" />
              </div> : 'Submit'
            }
          </button>
        </div>
        <div>
          {/* Single Film */}
          {movieType === 'Single Film' && (
            <div className={`rounded-2xl h-full ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-indigo-50"}`}>
              {/* Form for collecting movie information */}
              <div className='flex justify-center items-center'>
                <input
                  type='text'
                  placeholder='Movie Title'
                  value={movieTitle}
                  onChange={(e) => setMovieTitle(e.target.value)}
                  required
                  className={`m-2 w-fit min-w-[250px] h-[45px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin border-0 resize-none shadow-md ${darkMode ? "text-black" : "light-mode bg-white"}`}
                />
                {movieType === 'Single Film' && (
                  <input
                    type='text'
                    placeholder='Duration'
                    value={duration}
                    required
                    onChange={(e) => setDuration(e.target.value)}
                    className={`m-2 w-[88px] h-[45px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin border-0 resize-none shadow-md ${darkMode ? "text-black" : "light-mode bg-white"}`}
                  />
                )}
                {/* Dropdown to film type*/}
                <select
                  id="filmType"
                  value={selectedFilm}
                  onChange={handleSelectFilm}
                  className='m-2 w-[180px] h-[45px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin border-0 resize-none shadow-md text-slate-500'
                >
                  <option value="">Select Film Type</option>
                  {filmTypes.map((type) => (
                    <option key={type.id} value={type.name} className='text-black'>
                      {type.name}
                    </option>
                  ))}
                </select>
                {selectedFilm === 'Full Movie' && (
                  <div className='flex items-center'>
                    <input
                      type='text'
                      placeholder='Download Link'
                      value={downloadLink}
                      onChange={(e) => setDownloadLink(e.target.value)}
                      required
                      className={`m-2 w-fit min-w-[250px] h-[45px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin border-0 resize-none shadow-md ${darkMode ? "text-black" : "light-mode bg-white"}`}
                    />
                  </div>
                )}
              </div>
              {movieTitle && (
                <div>
                  <div className='flex flex-col gap-2 items-center'>
                    <div className='ml-2 mt-4 font-bold text-slate-400'>Upload movie cover image</div>
                    <input
                      type='file'
                      accept='image/*'
                      required
                      onChange={handleMovieCoverUpload}
                      className={`m-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md ${darkMode ? "text-black" : "light-mode bg-white"}`}
                    />
                    {progress !== null && <p className='text-[12px] p-1 text-green-500'>Upload {progress}%</p>}
                  </div>
                  <div className='flex flex-col gap-2 items-center'>
                    <div className='ml-2 mt-4 font-bold text-slate-400'>Upload movie banner image</div>
                    <input
                      type='file'
                      accept='image/*'
                      required
                      onChange={handleMovieBannerUpload}
                      className='m-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                    />
                    {bannerProgress !== null && <p className='text-[12px] p-1 text-green-500'>Upload {bannerProgress}%</p>}
                    {movieBanner && (
                      <div>
                        <img src={movieBanner} alt='Banner' className='max-md:w-full w-[280px] mt-2' />
                      </div>
                    )}
                  </div>
                  <div className='flex flex-col w-full justify-center items-center'>
                    <div className='ml-2 mt-4 font-bold text-slate-400'>Upload video trailer</div>
                    <div className='flex gap-4'>
                      <label>
                        <input
                          type='radio'
                          name='trailerSource'
                          value='file'
                          checked={trailerSource === 'file'}
                          onChange={() => setTrailerSource('file')}
                          className='mx-2'
                        />
                        Upload File
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='trailerSource'
                          value='url'
                          checked={trailerSource === 'url'}
                          onChange={() => setTrailerSource('url')}
                          className='mx-2'
                        />
                        Link URL
                      </label>
                    </div>
                    {trailerSource === 'file' && (
                      <input
                        type="file"
                        accept="video/*"
                        onChange={handleTrailerUpload}
                        className={`m-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md ${darkMode ? "text-black" : "light-mode bg-white"}`}
                      />
                    )}
                    {trailerSource === 'url' && (
                      <input
                        type="text"
                        value={trailerType === 'url' ? trailer : ''}
                        onChange={handleTrailerURLChange}
                        placeholder="Enter trailer URL"
                        className='m-2 w-[50%] max-sm:w-[80%] p-2 rounded-xl shadow-md'
                      />
                    )}
                    {trailerProgress !== null && <p className='text-[12px] p-1 text-green-500'>Upload {trailerProgress}%</p>}
                  </div>
                </div>
              )}
              <div className='m-2'>
                <div className='ml-2 font-bold text-slate-400'>Select Genre</div>
                {/* Checkbox inputs for genres */}
                {genresArray.map(genre => (
                  <label key={genre} className="inline-flex items-center mr-4 flex-wrap m-2 font-semibold text-slate-600">
                    <input
                      className='rounded'
                      type="checkbox"
                      value={genre}
                      checked={selectedGenres.includes(genre)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedGenres([...selectedGenres, genre]);
                        } else {
                          setSelectedGenres(selectedGenres.filter(item => item !== genre));
                        }
                      }}
                    />
                    <span className="ml-2">{genre}</span>
                  </label>
                ))}
              </div>

              {/* Description */}
              <div className='flex w-full justify-center items-center'>
                <textarea
                  name='Description'
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className='m-2 w-[90%] p-2 rounded-xl resize-none shadow-md'
                />
              </div>

              {selectedFilm === 'Upload Parts' && (
                <>
                  <div className='ml-2 mt-5 font-bold text-slate-400 text-center'>Parts</div>
                  {movieParts.map((part, index) => (
                    <div key={index} className="flex mx-2 mb-2 items-center justify-center">
                      <input
                        type="text"
                        placeholder="Title"
                        value={part.title}
                        onChange={(e) => handlePartChange(index, 'title', e.target.value)}
                        className='my-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                      />
                      <div className='text-slate-400 font-bold resize-none shadow-md'>
                        -
                      </div>
                      <input
                        type="text"
                        placeholder="Download Link"
                        value={part.downloadLink}
                        onChange={(e) => handlePartChange(index, 'downloadLink', e.target.value)}
                        className='my-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                      />
                      <button
                        className='m-2 hover:bg-red-500 hover:text-white w-[40px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md ml-2 text-red-600'
                        onClick={() => handleRemovePart(index)}
                      >
                        X
                      </button>
                    </div>
                  ))}
                  <div className='flex w-full h-[100px] justify-center items-center'>
                    <button onClick={addPart} className='text-center text-blue-600 cursor-pointer border-spacing-4 rounded-xl border-blue-600 hover:bg-blue-600 hover:text-white hover:font-bold border-2 p-2 w-fit'>Add Part</button>
                  </div>
                </>)}
              <div className='flex w-full h-[100px] justify-center items-center'>
                <button onClick={handleCancel} className='text-center text-red-500 cursor-pointer border-spacing-4 rounded-xl border-red-500 hover:bg-red-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>Cancel Release</button>
              </div>
            </div>
          )}
          {/* Season */}
          {movieType === 'Season' && (
            <div className={`rounded-2xl h-full ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-indigo-50"}`}>
              {/* Form for collecting season information */}
              <div>
                <div className='flex justify-center items-center'>
                  <input
                    type='text'
                    placeholder='Movie Title'
                    value={movieTitle}
                    onChange={(e) => setMovieTitle(e.target.value)}
                    required
                    className='m-2 w-fit min-w-[250px] h-[45px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                  />
                  {movieType === 'Single Film' && (
                    <input
                      type='text'
                      placeholder='Duration'
                      value={duration}
                      required
                      onChange={(e) => setDuration(e.target.value)}
                      className='m-2 w-[88px] h-[45px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                    />
                  )}
                  <div className='flex items-center'>
                  </div>
                  {/* Dropdown to movie production country */}
                  <select
                    id="movieCountry"
                    value={selectedCountry}
                    onChange={handleSelectCountry}
                    className='m-2 w-[210px] h-[45px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin border-0 resize-none shadow-md text-slate-500'
                  >
                    <option value="">Select Movie Country</option>
                    {seasonCountries.map((country) => (
                      <option key={country.id} value={country.name} className='text-black'>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  <label className="inline-flex items-center mr-4 flex-wrap m-2 font-semibold text-slate-600">
                    <input
                      className='rounded'
                      type="checkbox"
                      value={isFinished}
                      checked={isFinished}
                      onChange={handleCheckboxChange}
                    />
                    <span className="ml-2">{isFinished ? "The End" : "Final"}</span>
                  </label>
                </div>
                {movieTitle && (
                  <div>
                    <div className='flex flex-col gap-2 items-center'>
                      <div className='ml-2 mt-4 font-bold text-slate-400'>Upload movie cover image</div>
                      <input
                        type='file'
                        accept='image/*'
                        required
                        onChange={handleMovieCoverUpload}
                        className='m-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                      />
                      {progress !== null && <p className='text-[12px] p-1 text-green-500'>Upload {progress}%</p>}
                    </div>
                    <div className='flex flex-col gap-2 items-center'>
                      <div className='ml-2 mt-4 font-bold text-slate-400'>Upload movie banner image</div>
                      <input
                        type='file'
                        accept='image/*'
                        required
                        onChange={handleMovieBannerUpload}
                        className='m-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                      />
                      {bannerProgress !== null && <p className='text-[12px] p-1 text-green-500'>Upload {bannerProgress}%</p>}
                      {movieBanner && (
                        <div>
                          <img src={movieBanner} alt='Banner' className='max-md:w-full w-[280px] mt-2' />
                        </div>
                      )}
                    </div>

                    <div className='flex flex-col w-full justify-center items-center'>
                      <div className='ml-2 mt-4 font-bold text-slate-400'>Upload video trailer</div>
                      <div className='flex gap-4'>
                        <label>
                          <input
                            type='radio'
                            name='trailerSource'
                            value='file'
                            checked={trailerSource === 'file'}
                            onChange={() => setTrailerSource('file')}
                            className='mx-2'
                          />
                          Upload File
                        </label>
                        <label>
                          <input
                            type='radio'
                            name='trailerSource'
                            value='url'
                            checked={trailerSource === 'url'}
                            onChange={() => setTrailerSource('url')}
                            className='mx-2'
                          />
                          Link URL
                        </label>
                      </div>
                      {trailerSource === 'file' && (
                        <input
                          type="file"
                          accept="video/*"
                          onChange={handleTrailerUpload}
                          className={`m-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md ${darkMode ? "text-black" : "light-mode bg-white"}`}
                        />
                      )}
                      {trailerSource === 'url' && (
                        <input
                          type="text"
                          value={trailerType === 'url' ? trailer : ''}
                          onChange={handleTrailerURLChange}
                          placeholder="Enter trailer URL"
                          className='m-2 w-[50%] max-sm:w-[80%] p-2 rounded-xl shadow-md'
                        />
                      )}
                      {trailerProgress !== null && <p className='text-[12px] p-1 text-green-500'>Upload {trailerProgress}%</p>}
                    </div>
                  </div>
                )}
                <div className='m-2'>
                  <div className='ml-2 font-bold text-slate-400'>Select Genre</div>
                  {/* Checkbox inputs for genres */}
                  {genresArray.map(genre => (
                    <label key={genre} className="inline-flex items-center mr-4 flex-wrap m-2 font-semibold text-slate-600">
                      <input
                        className='rounded'
                        type="checkbox"
                        value={genre}
                        checked={selectedGenres.includes(genre)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedGenres([...selectedGenres, genre]);
                          } else {
                            setSelectedGenres(selectedGenres.filter(item => item !== genre));
                          }
                        }}
                      />
                      <span className="ml-2">{genre}</span>
                    </label>
                  ))}
                </div>

                {/* Description */}
                <div className='flex w-full justify-center items-center'>
                  <textarea
                    name='Description'
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className='m-2 w-[90%] p-2 rounded-xl resize-none shadow-md'
                  />
                </div>
              </div>
              <div className='ml-2 font-bold text-slate-400 text-center'>Episodes</div>
              {episodes.map((episode, index) => (
                <div key={index} className="flex mx-2 mb-2 items-center justify-center">
                  <input
                    type="text"
                    placeholder="Title"
                    value={episode.title}
                    onChange={(e) => handleEpisodeChange(index, 'title', e.target.value)}
                    className='my-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                  />
                  <div className='text-slate-400 font-bold resize-none shadow-md'>
                    -
                  </div>
                  <input
                    type="text"
                    placeholder="Download Link"
                    value={episode.downloadLink}
                    onChange={(e) => handleEpisodeChange(index, 'downloadLink', e.target.value)}
                    className='my-2 w-fit min-w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md'
                  />
                  <button
                    className='m-2 hover:bg-red-500 hover:text-white w-[40px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md ml-2 text-red-600'
                    onClick={() => handleRemoveEpisode(index)}
                  >
                    X
                  </button>
                </div>
              ))}
              <div className='flex w-full h-[100px] justify-center items-center'>
                <button onClick={handleAddEpisode} className='text-center text-blue-600 cursor-pointer border-spacing-4 rounded-xl border-blue-600 hover:bg-blue-600 hover:text-white hover:font-bold border-2 p-2 w-fit'>Add Episode</button>
              </div>
              <div className='flex w-full h-[100px] justify-center items-center'>
                <button onClick={handleCancel} className='text-center text-red-500 cursor-pointer border-spacing-4 rounded-xl border-red-500 hover:bg-red-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>Cancel Release</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div >
  );
};

export default CreateRelease;