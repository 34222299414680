import React, { useState, useEffect, useContext } from 'react';
import { collection, doc, addDoc, setDoc, getDoc, getDocs, query, where, orderBy, serverTimestamp } from "firebase/firestore";
import { db, auth } from '../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { DarkModeContext } from '../context/darkModeContext';
import { icon } from '../assets';

const CreatePost = () => {
  const navigate = useNavigate();
  const user = auth.currentUser.uid;
  const { darkMode } = useContext(DarkModeContext);
  const [seasons, setSeasons] = useState([]);
  const [filteredSeasons, setFilteredSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState('');
  const [seasonData, setSeasonData] = useState(null);
  const [newEpisodes, setNewEpisodes] = useState([]);
  const [postContent, setPostContent] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false); // Loading state
  const [finishMovie, setFinishMovie] = useState(false); // Added state for finish movie option
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const seasonQuery = query(collection(db, 'movies'), where('Type', '==', 'Season'), where('ProducerID', '==', user));
        const seasonSnapshot = await getDocs(seasonQuery);
        const seasonList = seasonSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSeasons(seasonList);
        setFilteredSeasons(seasonList);
      } catch (error) {
        console.error('Error fetching seasons: ', error);
        // Handle error fetching seasons
      }
    };
  
    fetchSeasons();
  }, [user]);

  useEffect(() => {
    const filteredSeasons = seasons.filter(season => season.MovieTitle.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredSeasons(filteredSeasons);
  }, [searchTerm, seasons]);

  useEffect(() => {
    const fetchSeasonData = async () => {
      if (selectedSeason) {
        const seasonDocRef = doc(db, 'movies', selectedSeason);
        const seasonSnapshot = await getDoc(seasonDocRef);
        if (seasonSnapshot.exists()) {
          setSeasonData(seasonSnapshot.data());
        }
      }
    };

    fetchSeasonData();
  }, [selectedSeason]);

  const handleSelectSeason = (e) => {
    setSelectedSeason(e.target.value);
  };

  const handleAddNewEpisode = () => {
    const totalEpisodes = seasonData?.Episodes?.length || 0;
    const nextEpisodeNumber = totalEpisodes + newEpisodes.length + 1;
    const newEpisodeTitle = `EP ${nextEpisodeNumber}`;
    setNewEpisodes([...newEpisodes, { title: newEpisodeTitle, downloadLink: '' }]);
  };

  const handleRemoveEpisode = (indexToRemove) => {
    setNewEpisodes(newEpisodes.filter((_, index) => index !== indexToRemove));
  };

  const handleEpisodeChange = (index, key, value) => {
    const updatedEpisodes = [...newEpisodes];
    updatedEpisodes[index][key] = value;
    setNewEpisodes(updatedEpisodes);
  };

  useEffect(() => {
    setSubmitDisabled(!(selectedSeason && newEpisodes.length > 0 && newEpisodes.every(episode => episode.title && episode.downloadLink)));
  }, [selectedSeason, newEpisodes]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let ProducerID = '';
      let ProducerName = '';
      let ProducerImageUrl = '';
      let MovieTitle = '';
      let CoverImage = '';
      if (selectedSeason) {
        const seasonDocRef = doc(db, 'movies', selectedSeason);
        const seasonSnapshot = await getDoc(seasonDocRef);
        if (seasonSnapshot.exists()) {
          const seasonData = seasonSnapshot.data();
          ProducerID = seasonData.ProducerID || '';
          ProducerName = seasonData.ProducerName || '';
          ProducerImageUrl = seasonData.ProducerImageUrl || '';
          MovieTitle = seasonData.MovieTitle || '';
          CoverImage = seasonData.CoverImage || '';
        }
      }
  
      const postRef = await addDoc(collection(db, 'posts'), {
        season: selectedSeason,
        episodes: newEpisodes,
        content: postContent,
        timestamp: serverTimestamp(),
        ProducerID: ProducerID,
        ProducerName: ProducerName,
        ProducerImageUrl: ProducerImageUrl,
        MovieTitle: MovieTitle,
        CoverImage: CoverImage,
      });
  
      if (selectedSeason) {
        const seasonDocRef = doc(db, 'movies', selectedSeason);
        const seasonSnapshot = await getDoc(seasonDocRef);
        if (seasonSnapshot.exists()) {
          const seasonData = seasonSnapshot.data();
          const updatedEpisodes = [...seasonData.Episodes, ...newEpisodes];
          await setDoc(seasonDocRef, { Episodes: updatedEpisodes, Finish: finishMovie }, { merge: true });
        }
      }
  
      toast.success('Weekly movie post created successfully');
      navigate('/create');
      setSelectedSeason('');
      setNewEpisodes([]);
      setPostContent('');
      setLoading(false);
    } catch (error) {
      console.error('Error creating post: ', error);
      toast.error('Failed to create post');
      setLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-xl font-semibold mb-4">Create Post</h2>
      <div className="mb-4">
        <input
          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Search Seasons"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="mt-2 block w-full border bg-white rounded px-3 py-2 focus:outline-none"
          value={selectedSeason}
          onChange={handleSelectSeason}
        >
          <option value="">Select Season</option>
          {filteredSeasons.map(season => (
            <option key={season.id} value={season.id}>{season.MovieTitle}</option>
          ))}
        </select>
      </div>
      {seasonData && (
        <div>
          <h3>{seasonData.MovieTitle}</h3>
          <div className="h-[180px] w-[120px] hover:ml-[12px] shadow-lg rounded-xl hover:shadow-xl my-4 flex-shrink-0">
            <img src={seasonData.CoverImage} alt='Movie Cover' className='h-[180px] w-[120px] hover:h-[190px] hover:w-[130px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
          </div>
          <p>Genres: {seasonData.Genres.join(', ')}</p>
          <div>
            <h4>Episodes</h4>
            <p>Total Episodes: {seasonData.Episodes?.length}</p>
            <p>Last Episode: {seasonData.Episodes[seasonData.Episodes?.length - 1]?.title}</p>
          </div>
        </div>
      )}
      <div>
        <h4>New Episodes</h4>
        {newEpisodes.map((episode, index) => (
          <div key={index} className="mb-4">
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Episode Title"
              value={episode.title}
              onChange={(e) => handleEpisodeChange(index, 'title', e.target.value)}
            />
            <input
              className="mt-2 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Download Link"
              value={episode.downloadLink}
              onChange={(e) => handleEpisodeChange(index, 'downloadLink', e.target.value)}
            />
            <button className="mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={() => handleRemoveEpisode(index)}>Remove</button>
          </div>
        ))}
        <button className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${selectedSeason ? '' : 'bg-gray-500 cursor-not-allowed'}`} onClick={handleAddNewEpisode} disabled={!selectedSeason}>Add Episode</button>
      </div>
      <div className="mt-4">
        <h4>Post Content</h4>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={postContent}
          onChange={(e) => setPostContent(e.target.value)}
          rows={5}
          cols={50}
        />
      </div>
      <div>
        <h4>Finish Movie</h4>
        <div>
          <label>
            <input
              type="radio"
              name="finishMovie"
              value="continue"
              checked={!finishMovie}
              onChange={() => setFinishMovie(false)}
            />
            Continue
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              name="finishMovie"
              value="final"
              checked={finishMovie}
              onChange={() => setFinishMovie(true)}
            />
            Final
          </label>
        </div>
      </div>
      <button className={`mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${submitDisabled || loading ? 'bg-gray-400 cursor-not-allowed' : ''}`} onClick={handleSubmit} disabled={submitDisabled || loading}>
        {loading ? 'Submitting...' : 'Submit'}
      </button>
    </div>
  );
};

export default CreatePost;