import { useContext, useState, useEffect } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth, db, storage } from "../firebase";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { logo2 } from "../assets";
import PropagateLoader from 'react-spinners/esm/PropagateLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";

const Register = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  const handleNextStep = (data) => {
    setUserData((prevData) => ({ ...prevData, ...data }));
    setStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const progressPercentage = (step / 4) * 100;

  const handleFinalSubmit = () => {
    // Perform final submission with all userData collected
    console.log(userData); // Here you can submit all userData to backend
    navigate("/dashboard"); // Redirect to dashboard or desired location after registration
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 onNextStep={handleNextStep} />;
      case 2:
        return <Step2 onNextStep={handleNextStep} onPreviousStep={handlePreviousStep} />;
      case 3:
        return <Step3 onNextStep={handleNextStep} onPreviousStep={handlePreviousStep} userData={userData} />;
      case 4:
        return <Step4 onPreviousStep={handlePreviousStep} onSubmit={handleFinalSubmit} userData={userData} />;
      default:
        return null;
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <div className="text-center text-sm mt-4">
        Step {step} of 4

        {/* Progress Line */}
        <div className="relative pt-1 mt-2">
          <div className="flex h-2 overflow-hidden bg-gray-200 rounded">
            <div
              style={{ width: `${progressPercentage}%` }}
              className="flex flex-col justify-center bg-blue-500 shadow-none whitespace-nowrap text-white"
            ></div>
          </div>
        </div>
      </div>
      {renderStep()}
      {step !== 1 && (
        <button onClick={handlePreviousStep} className="mt-4 px-4 py-2 bg-gray-200 rounded-md">
          Previous
        </button>
      )}
      {step !== 4 && (
        <button onClick={handleNextStep} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md">
          Next
        </button>
      )}
      <div className="text-center mt-2">
        <div className="text-center text-[14px] text-zinc-400 pt-4">Already have an account?</div>
        <Link to="/login" className="text-center font-bold text-blue-500 pb-2 cursor-pointer">Sign In</Link>
      </div>
    </div>
  );
};

const Step1 = () => {
  return (
    <div>
      <div>
        Step 1
      </div>
      <div>
        Welcome to iTrood Production
      </div>
    </div>
  )
}

const Step2 = () => {
  return (
    <div>
      <div>
        Step 2
      </div>
      <div>
        Basic Information
      </div>
    </div>
  )
}

const Step3 = () => {
  return (
    <div>
      <div>
        Step 3
      </div>
      <div>
        Terms & Condition, Contract
      </div>
    </div>
  )
}

const Step4 = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState("");
  const [progress, setProgress] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const uploadFile = () => {
      if (!file) return;
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, `users/${name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.log(error);
          toast.error("Error occurred while uploading an image. Please try again.");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    uploadFile();
  }, [file]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    // Check if required fields are filled
    if (!data.img || !data.displayName || !data.email || !data.phone || !data.country) {
      setError("Please fill in all required fields (Image, Full Name, Email, Phone, Country).");
      return;
    }

    setLoading(true);
    setError(null);

    // Normalize email address: remove spaces and convert to lowercase
    const trimmedEmail = data.email.trim(); // Remove leading and trailing spaces
    const normalizedEmail = trimmedEmail.toLowerCase(); // Convert to lowercase

    try {
      const res = await createUserWithEmailAndPassword(auth, normalizedEmail, data.password);

      // Create the user document with the appropriate subscription value under 'users' collection
      await setDoc(doc(db, "users", res.user.uid), {
        ...data,
        email: normalizedEmail,
        id: res.user.uid,
        verification: '',
        status: 'producer',
        earningBalance: '0',
        earningsTotalRevenue: '0',
        earningsWithdrawedAmount: '0',
        subscription: 'notPaid',
        timeStamp: serverTimestamp(),
        origin: 'users'
      });

      navigate(-1);
      setLoading(false);
      toast.success("User created successfully!");
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(`Error occurred while creating a user. ${err.message}`);
    }
  };

  const handleGetAccessCode = () => {
    // Open email client with pre-filled email to support@itrood.com
    window.location.href = "mailto:support@itrood.com?subject=Producer%20Registration%20Request&body=I%20want%20to%20be%20registered%20to%20iTrood%20Production";
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center overflow-y-auto scrollbar-hide pt-[100px]">
      <img src={logo2} className="w-[200px] max-md:w-[150px] shrink-0 m-10" alt="iTrood logo" />
      <form onSubmit={handleAdd} className="flex flex-col items-center">
        <div className="text-center font-bold text-slate-700 p-2">Sign Up</div>
        <img
          src={
            file
              ? URL.createObjectURL(file)
              : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
          }
          alt="iTrood"
          className="w-32 h-32 rounded-full object-cover mx-auto"
        />
        <input
          type="file"
          id="file"
          onChange={(e) => setFile(e.target.files[0])}
          className="m-2 w-[250px] max-md:w-[250px] h-[40px] rounded-xl scrollbar-thin border-0 resize-none shadow-md"
        />
        <div className="w-full justify-center items-center grid grid-cols-2 max-sm:flex max-sm:flex-col gap-2">
          {inputs.map((input) => (
            <div key={input.id}>
              <input
                id={input.id}
                type={input.type}
                placeholder={input.placeholder}
                required
                onChange={handleInputChange}
                className="w-[350px] max-md:w-[300px] h-10 border border-gray-300 rounded-md px-2 mt-2"
              />
            </div>
          ))}
          <div className="w-[350px] max-md:w-[300px]">
            <div className="my-2 w-full text-slate-400 bg-indigo-50 h-[40px] px-2 py-1 border-b border-slate-300 rounded scrollbar-thin border-0 resize-none shadow-sm">Movie Producer | DJ</div>
          </div>
        </div>
        {loading ? (
          <div className="w-[350px] max-md:w-[200px] h-10 pb-4 flex bg-zinc-100 rounded-md mt-2 justify-center items-center">
            <PropagateLoader color="#3693d6" />
          </div>
        ) : (
          <button
            type="submit"
            disabled={progress !== null && progress < 100}
            className="w-[350px] max-md:w-[200px] h-10 bg-purple-500 text-white font-bold rounded-md mt-2 cursor-pointer"
          >
            Register
          </button>
        )}
        {error && <span className="text-red-500 text-sm mt-2">{error}</span>}
      </form>
    </div>
  );
};

const inputs = [
  {
    id: "displayName",
    label: "Full Name",
    type: "text",
    placeholder: "Full Name",
  },
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "username",
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "Email",
  },
  {
    id: "phone",
    label: "Phone",
    type: "text",
    placeholder: "+255XXXXXXXXX",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
    placeholder: "Password",
  },
  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "Elton St. 216 Kariakoo",
  },
  {
    id: "country",
    label: "Country",
    type: "text",
    placeholder: "Tanzania",
  },
];


export default Register;