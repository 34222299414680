import React, { useContext, useEffect, useState } from "react";
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { DarkModeContext } from '../context/darkModeContext';
import Table from "../code/Table";
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { useUserData } from "../lib/userData";
import Withdraw from "../pages/Withdraw";

const Dashboard = () => {
  const userData = useUserData();
  const { darkMode } = useContext(DarkModeContext);
  const [newPosts, setNewPosts] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [totalNewPosts, setTotalNewPosts] = useState(0);
  const [newMovies, setNewMovies] = useState([]);
  const [totalNewMovies, setTotalNewMovies] = useState(0);
  const [totalTrendingMovies, setTotalTrendingMovies] = useState(0);
  const [totalDistributors, setTotalDistributors] = useState(0);
  const [totalProducers, setTotalProducers] = useState(0);
  const [totalMovies, setTotalMovies] = useState(0);
  const [totalSeasons, setTotalSeasons] = useState(0);
  const [totalSingleFilms, setTotalSingleFilms] = useState(0);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        // Fetch all movies from the 'movies' collection
        const moviesCollection = collection(db, 'movies');
        const querySnapshot = await getDocs(moviesCollection);
        const fetchedMovies = querySnapshot.size; // Get the total number of documents in the query snapshot
        setTotalMovies(fetchedMovies);

        // Filter movies based on 'Type' field
        const seasonQuery = query(moviesCollection, where('Type', '==', 'Season'));
        const seasonSnapshot = await getDocs(seasonQuery);
        const fetchedSeasons = seasonSnapshot.size; // Get the total number of documents in the season query snapshot
        setTotalSeasons(fetchedSeasons);

        const singleFilmQuery = query(moviesCollection, where('Type', '==', 'Single Film'));
        const singleFilmSnapshot = await getDocs(singleFilmQuery);
        const fetchedSingleFilms = singleFilmSnapshot.size; // Get the total number of documents in the single film query snapshot
        setTotalSingleFilms(fetchedSingleFilms);
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };

    fetchMovies();
  }, []);

  useEffect(() => {
    const fetchProducers = async () => {
      try {
        // Query the 'users' collection for users with 'status' field set to 'producer'
        const usersCollection = collection(db, 'users');
        const q = query(usersCollection, where('status', '==', 'producer'));
        const querySnapshot = await getDocs(q);
        const fetchedProducers = querySnapshot.size;
        setTotalProducers(fetchedProducers);
      } catch (error) {
        console.error('Error fetching producers:', error);
      }
    };

    fetchProducers();
  }, []);

  useEffect(() => {
    const fetchDistributors = async () => {
      try {
        // Query the 'users' collection for users with 'status' field set to 'distributor'
        const usersCollection = collection(db, 'users');
        const q = query(usersCollection, where('status', '==', 'distributor'));
        const querySnapshot = await getDocs(q);
        const fetchedDistributors = querySnapshot.size;
        setTotalDistributors(fetchedDistributors);
      } catch (error) {
        console.error('Error fetching distributors:', error);
      }
    };

    fetchDistributors();
  }, []);

  useEffect(() => {
    const fetchTrendingMovies = async () => {
      try {
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        const startOfMonthTimestamp = Timestamp.fromDate(startOfMonth);
        const endOfMonthTimestamp = Timestamp.fromDate(endOfMonth);

        const moviesCollection = collection(db, 'movies');
        const q = query(
          moviesCollection,
          where('timeStamp', '>=', startOfMonthTimestamp),
          where('timeStamp', '<=', endOfMonthTimestamp)
        );
        const querySnapshot = await getDocs(q);
        const fetchedMovies = querySnapshot.size;
        setTotalTrendingMovies(fetchedMovies);
      } catch (error) {
        console.error('Error fetching trending movies:', error);
      }
    };

    fetchTrendingMovies();
  }, []);

  useEffect(() => {
    const fetchNewMovies = async () => {
      try {
        console.log("Fetching new movies...");
        const currentDate = new Date();
        const currentDay = currentDate.getDay();

        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - (currentDay % 7));
        startOfWeek.setHours(0, 0, 0, 0);

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999);

        const startOfWeekTimestamp = Timestamp.fromDate(startOfWeek);
        const endOfWeekTimestamp = Timestamp.fromDate(endOfWeek);

        const moviesCollection = collection(db, 'movies');
        const q = query(
          moviesCollection,
          where('timeStamp', '>=', startOfWeekTimestamp),
          where('timeStamp', '<=', endOfWeekTimestamp)
        );
        const querySnapshot = await getDocs(q);
        const fetchedMovies = [];
        querySnapshot.forEach((doc) => {
          fetchedMovies.push(doc.data());
        });
        // Sort the fetched movies by timestamp in descending order
        fetchedMovies.sort((a, b) => b.timeStamp.toMillis() - a.timeStamp.toMillis());
        setNewMovies(fetchedMovies);
        setTotalNewMovies(fetchedMovies?.length);
        console.log("Fetched movies:", fetchedMovies);
      } catch (error) {
        console.error('Error fetching new movies:', error);
      }
    };

    fetchNewMovies();
  }, []);

  useEffect(() => {
    const fetchNewPosts = async () => {
      try {
        console.log("Fetching new posts...");
        // Calculate the start and end dates for the current week
        const startOfWeek = new Date(currentDate);
        startOfWeek.setHours(0, 0, 0, 0); // Set to the beginning of the day
        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999); // Set to the end of the day

        // Convert start and end of the week to Firestore Timestamps
        const startOfWeekTimestamp = Timestamp.fromDate(startOfWeek);
        const endOfWeekTimestamp = Timestamp.fromDate(endOfWeek);

        // Fetch posts made during the current week (starting from Monday)
        const postsCollection = collection(db, 'posts');
        const q = query(
          postsCollection,
          where('timestamp', '>=', startOfWeekTimestamp),
          where('timestamp', '<=', endOfWeekTimestamp)
        );
        const querySnapshot = await getDocs(q);
        const fetchedPosts = [];
        querySnapshot.forEach((doc) => {
          fetchedPosts.push(doc.data());
        });
        // Sort the fetched posts by timestamp in descending order
        fetchedPosts.sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis());
        console.log("Fetched posts:", fetchedPosts);
        setNewPosts(fetchedPosts);

        // Update the total number of posts
        setTotalNewPosts(fetchedPosts?.length);
      } catch (error) {
        console.error('Error fetching new posts:', error);
      }
    };

    fetchNewPosts();
  }, [currentDate]);

  const getCurrentDayName = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[currentDate.getDay()];
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className='w-full'>
        <div className='font-bold text-[18px]'>Overview</div>
        <div className='flex w-full overflow-x-hidden'>
          <div className='flex flex-row flex-nowrap w-full max-sm:w-[80%] justify-start overflow-x-auto gap-4 px-[6px] scrollbar-hide' style={{ WebkitOverflowScrolling: 'auto' }}>
            <div className={`h-[140px] w-[16%] max-sm:min-w-[60%] max-md:min-w-[30%] p-4 my-2 rounded-2xl ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode shadow-lg shadow-slate-200 bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
              <Link to={`/posts`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>This Week</Link>
              <div className='text-[40px] font-bold text-green-500'><CountUp start={0} end={totalNewPosts?.toLocaleString()} duration={2} /></div>
            </div>
            <div className={`h-[140px] w-[16%] max-sm:min-w-[60%] max-md:min-w-[30%] p-4 my-2 rounded-2xl ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode shadow-lg shadow-slate-200 bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
              <Link to={`new-release/`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>New Released</Link>
              <div className='text-[40px] font-bold text-blue-500'><CountUp start={0} end={totalNewMovies?.toLocaleString()} duration={2} /></div>
            </div>
            <div className={`h-[140px] w-[16%] max-sm:min-w-[60%] max-md:min-w-[30%] p-4 my-2 rounded-2xl ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode shadow-lg shadow-slate-200 bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
              <Link to={`/trendings`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>Trendings</Link>
              <div className='text-[40px] font-bold text-red-500'><CountUp start={0} end={totalTrendingMovies?.toLocaleString()} duration={2} /></div>
            </div>
            <div className={`h-[140px] w-[16%] max-sm:min-w-[60%] max-md:min-w-[30%] p-4 my-2 rounded-2xl ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode shadow-lg shadow-slate-200 bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
              <Link to={`/distributors`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>Distributors</Link>
              <div className='text-[40px] font-bold text-orange-500'><CountUp start={0} end={totalDistributors?.toLocaleString()} duration={2} /></div>
            </div>
            <div className={`h-[140px] w-[16%] max-sm:min-w-[60%] max-md:min-w-[30%] p-4 my-2 rounded-2xl ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode shadow-lg shadow-slate-200 bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
              <Link to={`/producers`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>Producers</Link>
              <div className='text-[40px] font-bold text-yellow-300'><CountUp start={0} end={totalProducers?.toLocaleString()} duration={2} /></div>
            </div>
            <div className={`h-[140px] w-[16%] max-sm:min-w-[60%] max-md:min-w-[30%] p-4 my-2 rounded-2xl ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode shadow-lg shadow-slate-200 bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
              <Link to={`/movies`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>Movies</Link>
              <div>
                <div className='text-[30px] font-bold text-slate-400'><CountUp start={0} end={totalMovies?.toLocaleString()} duration={2} /></div>
                <div className='flex gap-2 justify-between mx-3'>
                  <div>
                    <div className='text-[14px] text-slate-600 font-semibold'><CountUp start={0} end={totalSingleFilms?.toLocaleString()} duration={2} /></div>
                    <div className='text-[10px] text-slate-400 font-bold'>Single films</div>
                  </div>
                  <div>
                    <div>
                      <div className='text-[14px] text-slate-600 font-semibold'><CountUp start={0} end={totalSeasons?.toLocaleString()} duration={2} /></div>
                      <div className='text-[10px] text-slate-400 font-bold'>Seasons</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row max-sm:flex-col flex-nowrap w-full max-sm:pr-[80px] md:pr-[80px] justify-start max-w-full min-w-0 gap-4 px-[6px]'>
        <div className={`h-[140px] w-[35%] max-sm:min-w-full md:min-w-[35%] p-4 my-2 rounded-2xl ${darkMode ? "hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
          <Link to={`/sales`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>Sale's Earnings</Link>
          <div className='text-[30px] font-bold text-slate-400'>Today</div>
          <div className='flex w-[50%] gap-2 justify-between'>
            <div>
              <div className='text-[14px] text-slate-600 font-semibold'><CountUp start={0} end={totalSeasons?.toLocaleString()} duration={2} /></div>
              <div className='text-[10px] text-slate-400 font-bold'>This week</div>
            </div>
            <div>
              <div className='text-[14px] text-slate-600 font-semibold'><CountUp start={0} end={totalSeasons?.toLocaleString()} duration={2} /></div>
              <div className='text-[10px] text-slate-400 font-bold'>Transactions</div>
            </div>
          </div>
        </div>
        <div className={`flex flex-col items-center h-[140px] w-[30%] max-sm:min-w-full md:min-w-[35%] p-4 my-2 rounded-2xl ${darkMode ? "hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
          <div className='font-semibold cursor-pointer hover:text-blue-500'>Balance</div>
          <div className='text-[40px] font-bold text-[#15fac3]'><strong className="text-[12px]">Tzs</strong> <CountUp start={0} end={userData?.earningBalance?.toLocaleString()} duration={2} /></div>
          <Withdraw balance={userData?.earningBalance?.toLocaleString()} />
        </div>
        <div className={`flex flex-col h-[140px] w-[35%] max-sm:min-w-full md:min-w-[35%] p-4 my-2 rounded-2xl items-end ${darkMode ? "hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
          <Link to={`/revenue`} className='font-semibold cursor-pointer hover:text-blue-500 hover:underline'>Revenue Chart</Link>
          <div className='text-[30px] font-bold text-slate-400'>Tzs <CountUp start={0} end={userData?.earningsTotalRevenue?.toLocaleString()} duration={2} /></div>
          <div className="flex flex-col-reverse w-full items-end">
            <div className='flex w-[50%] gap-2 justify-between'>
              <div>
                <div>
                  <div className='text-[14px] text-slate-600 font-semibold'><CountUp start={0} end={totalSeasons?.toLocaleString()} duration={2} /></div>
                  <div className='text-[10px] text-slate-400 font-bold'>Last month</div>
                </div>
              </div>
              <div>
                <div className='text-[14px] text-slate-600 font-semibold'>Tzs <CountUp start={0} end={userData?.earningsWithdrawedAmount?.toLocaleString()} duration={2} /></div>
                <div className='text-[10px] text-slate-400 font-bold'>Withdrawal</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className=''>
          <div className='flex justify-between items-center'>
            <div className='font-bold text-[15px]'>New released</div>
            <Link to={`/new-release`} className='text-blue-500 text-[12px] cursor-pointer'>View more</Link>
          </div>
          <div className=''></div>
        </div>
        <div className='gap-4 max-sm:grid-cols-1 grid grid-cols-2 m-[2px] px-2 py-2'>
          {newMovies.slice(0, 4).map((movie, index) => (
            <Link to={`/movies/${movie.Type === 'Single Film' ? 'film' : 'movie'}/${movie.id}`} key={index} className={`flex p-2 max-sm:w-[80%] justify-between items-center w-full h-[80px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-hide resize-none shadow-md ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]"}`}>
              <div className='flex gap-1 items-center'>
                <img src={movie.CoverImage} className='h-[50px] w-[35px] m-2 shrink-0 rounded-md bg-zinc-200 object-cover' />
                <div>
                  <p className='text-[14px] font-semibold'>{movie.MovieTitle}</p>
                  <p className='text-[12px] text-slate-400 font-bold'>{movie.Type === 'Season' ? `${movie.Type} | ${movie.Episodes?.length} Episodes` : movie.Type}</p>
                </div>
              </div>
              <div className='flex gap-2 justify-center'>
                <div className='flex m-1 h-[55px] w-[2px] bg-zinc-300' />
                <a href="" className='flex flex-col gap-1 justify-center items-center mr-2'>
                  <div className='h-[30px] w-[30px] justify-center items-center flex bg-zinc-100 hover:bg-zinc-200 rounded-full border'>
                    <img src={movie.ProducerImageUrl} className='h-[30px] w-[30px] rounded-full object-cover' />
                  </div>
                  <p className='text-[10px] font-semibold w-[50px] text-center'>{movie.ProducerName}</p>
                </a>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className=''>
        <div className='flex justify-between items-center'>
          <div className='font-bold text-[15px]'>This week post</div>
          <Link to={`/posts`} className='text-blue-500 text-[12px] cursor-pointer'>View more</Link>
        </div>
        <div className='max-sm:hi dden'>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;