import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

const Distributors = () => {
  const [distributors, setDistributors] = useState([]);

  useEffect(() => {
    const fetchDistributors = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('status', '==', 'distributor'));
        const querySnapshot = await getDocs(q);

        const fetchedDistributors = [];
        querySnapshot.forEach((doc) => {
          // Extract necessary fields from the user document
          const userData = doc.data();
          fetchedDistributors.push({
            id: doc.id,
            displayName: userData.displayName,
            img: userData.img, // Assuming you have a field 'img' in your user document
          });
        });

        setDistributors(fetchedDistributors);
      } catch (error) {
        console.error('Error fetching distributors: ', error);
      }
    };

    fetchDistributors();
  }, []);

  return (
    <div className='mx-auto px-4 py-8'>
      <h2 className="text-3xl font-semibold mb-4">Movie Distributors</h2>
      <div className='w-full flex flex-wrap gap-4 my-2 overflow-y-auto'>
        {distributors?.map((distributor) => (
          <div key={distributor.id} title={distributor.displayName} className='cursor-pointer h-[150px] w-[100px] justify-center line-clamp-1'>
            <img src={distributor.img} className='h-[100px] w-[100px] rounded-full flex-shrink-0 border shadow-lg object-cover' alt={distributor.displayName} />
            <p className='text-center font-semibold text-[14px]'>{distributor.displayName}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Distributors;