import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { icon, logo } from '../assets';
import { DarkModeContext } from '../context/darkModeContext';
import { MdSpaceDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { MdLocalMovies, MdVideoLibrary } from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import { FaRegSquarePlus } from "react-icons/fa6";
import { MdOutlineMovieFilter } from "react-icons/md";
import { BsFilePost } from "react-icons/bs";
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

const SideBar = () => {
  const { darkMode } = useContext(DarkModeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(getActiveLink(location.pathname));

  function getActiveLink(pathname) {
    const activeLink = navLinks.find(nav => `/${nav.id}` === pathname);
    return activeLink ? activeLink.title : "Dashboard";
  }

  const handleLogout = () => {
    auth.signOut().then(() => {
      // Remove user from localStorage
      localStorage.removeItem("user");
      // Redirect to the login page after logout
      navigate("/login");
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  return (
    <div className={`flex flex-col w-[220px] max-md:w-[100px] max-sm:w-[40px] h-[90%] rounded-xl m-4 max-md:m-2 max-sm-m-1 ml-10 max-md:ml-5 max-sm:ml-2 justify-between ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-white"}`}>
      <div className='flex flex-col max-sm:justify-center max-sm:items-center'>
        <div className='flex justify-center items-center p-5 max-sm:p-1 max-sm:h-[40px] max-sm:w-[40px] flex-shrink-0'>
          <img src={logo} className='h-[100px] w-[100px] max-md:h-[60px] max-md:w-[60px] max-sm:h-[40px] max-sm:w-[40px] shrink-0 object-cover' />
        </div>
        <div className='text-[12px] text-gray-400 font-bold text-center mb-4 max-sm:hidden'>iTrood Production</div>
        {navLinks.map((nav, index) => (
          <CustomLink to={`/${nav.id}`} key={nav.id} onClick={() => setActive(nav.title)}>
            <ul className={`flex gap-1 items-center py-2 m-1 p-4 max-sm:p-1 cursor-pointer ${darkMode ? "hover:bg-[#49425778] text-white" : "light-mode hover:bg-zinc-100 "}`}>
              {React.cloneElement(nav.icon, {
                className: `${active === nav.title ? darkMode ? "text-white" : "light-mode text-black" : "text-slate-500"} ${nav.icon.props.className}`
              })}
              <li pathname='iTrood Shop' className={`text-base font-medium leading-6 self-stretch max-md:text-[14px] max-sm:hidden ${active === nav.title ? "font-black" : "text-slate-500"}`}>
                {nav.title}
              </li>
            </ul>
          </CustomLink>
        ))}
      </div>
      <div className={`py-2 flex gap-2 justify-center items-center text-center mt-auto rounded-b-xl max-sm:text-[10px] ${darkMode ? "bg-white border hover:bg-slate-200 text-[#000000]" : "light-mode text-[#ffffff] bg-black hover:bg-zinc-900"}`} onClick={handleLogout}>
        <BiLogOutCircle size={20} />
        <div className='max-sm:hidden'>Logout</div>
      </div>
    </div>
  )
}

export const navLinks = [
  {
    id: "",
    title: "Dashboard",
    icon: <MdSpaceDashboard />
  },
  // {
  //   id: "users",
  //   title: "Users",
  //   icon: <FaUsers />
  // },
  {
    id: "movies",
    title: "Movies",
    icon: <MdLocalMovies />
  },
  {
    id: "create",
    title: "Create",
    icon: <FaRegSquarePlus />
  },
  {
    id: "new-release",
    title: "New Release",
    icon: <MdOutlineMovieFilter />
  },
  {
    id: "posts",
    title: "Posts",
    icon: <BsFilePost />
  },
];

function CustomLink({ to, children, ...props }) {
  return (
    <li>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}

export default SideBar;