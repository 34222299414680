import React, { useContext, useEffect, useState } from 'react';
import { DarkModeContext } from '../context/darkModeContext';
import { MdVerified, MdOutlineVerified } from "react-icons/md";
import { AiOutlineShareAlt } from "react-icons/ai";
import { collection, doc, query, orderBy, where, getDocs, getDoc, updateDoc, arrayUnion, arrayRemove, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { Link, useParams } from 'react-router-dom';
import { logo2 } from '../assets';
import { useUserData } from '../lib/userData';

const Profile = () => {
  const { dispatch, darkMode } = useContext(DarkModeContext);
  const { id } = useParams();
  const userData = useUserData();
  const [producer, setProducer] = useState(null);
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeSubscribersCount, setActiveSubscribersCount] = useState(0);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const fetchMovies = async () => {
      if (!userData) return;

      try {
        const moviesCollection = collection(db, 'movies');
        const q = query(
          moviesCollection,
          where('ProducerID', '==', userData.id),
          orderBy('timeStamp', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const fetchedMovies = [];
        querySnapshot.forEach((doc) => {
          fetchedMovies.push({ id: doc.id, ...doc.data() });
        });
        setMovies(fetchedMovies);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };

    fetchMovies();
  }, [userData]);

  useEffect(() => {
    const fetchActiveSubscribers = async () => {
      if (!userData) return;

      try {
        const subscriptionsCollection = collection(db, 'subscriptions');
        const q = query(subscriptionsCollection, where('producerId', '==', userData.id));
        const querySnapshot = await getDocs(q);

        let activeCount = 0;
        const currentTime = new Date();

        querySnapshot.forEach(async (doc) => {
          const subscriptionData = doc.data();
          const expireDate = subscriptionData.expireDate.toDate();

          if (!subscriptionData.expire && expireDate > currentTime) {
            activeCount++;
          } else if (expireDate < currentTime) {
            await updateDoc(doc.ref, {
              expire: true
            });
          }
        });

        setActiveSubscribersCount(activeCount);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    fetchActiveSubscribers();
  }, [userData]);

  const handleFollowClick = async () => {
    if (!producer || !userData) return;

    const userId = userData.id;
    const producerDocRef = doc(db, 'users', id);

    setIsFollowing((prevIsFollowing) => !prevIsFollowing);
    setFollowerCount((prevCount) => isFollowing ? prevCount - 1 : prevCount + 1);

    try {
      if (isFollowing) {
        await updateDoc(producerDocRef, { followers: arrayRemove(userId) });
      } else {
        await updateDoc(producerDocRef, { followers: arrayUnion(userId) });
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
      setIsFollowing((prevIsFollowing) => !prevIsFollowing);
      setFollowerCount((prevCount) => isFollowing ? prevCount + 1 : prevCount - 1);
    }
  };

  const handleShareClick = async () => {
    if (!userData) return;

    const shareData = {
      title: userData.displayName,
      text: `${userData.displayName}'s profile on iTrood`,
      url: `https://app.itrood.com/producer/${userData?.id}`,
    };

    try {
      await navigator.share(shareData);
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='h-full flex max-sm:flex-col-reverse gap-2 overflow-y-auto scrollbar-hide'>
      <div className='flex flex-col w-[70%] h-full max-sm:w-full gap-1'>
        <div className={`w-full flex max-sm:min-h-[60px] h-[10%] items-center rounded-xl overflow-x-auto ${darkMode ? "border hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-zinc-100"}`}>
          <div className='flex w-full justify-between mx-2'>
            <div className='flex gap-2 w-fit'>
              <div className='flex text-center justify-center items-center p-[2px] px-2 w-[150px] h-[35px] max-sm:w-[100px] max-sm:text-[12px]'>{userData?.followers?.length || 0} Followers</div>
              <div className='flex text-center justify-center items-center p-[2px] px-2 w-[100px] h-[35px] max-sm:w-[80px] max-sm:text-[12px]'>{activeSubscribersCount} $ubs</div>
            </div>
            {userData?.id !== userData?.id ? (
              <div className='flex gap-2 w-fit'>
                <div className='flex text-center justify-center items-center p-[2px] border rounded-lg px-2 w-[100px] h-[35px] max-sm:w-[80px] max-sm:text-[12px] cursor-pointer bg-blue-500 shadow-sm font-semibold text-white'>Follow</div>
                <div className='flex text-center justify-center items-center p-[2px] border rounded-lg px-2 w-[120px] h-[35px] max-sm:w-[80px] max-sm:text-[12px] cursor-pointer bg-teal-600 shadow-sm font-semibold text-white'>Subscribe</div>
              </div>
            ) : (
              <div className='flex gap-2 w-fit'>
                <Link to={`/create/post`} className='flex text-center justify-center items-center p-[2px] border rounded-lg px-2 w-[100px] h-[35px] max-sm:w-[80px] max-sm:text-[12px] cursor-pointer bg-zinc-400 hover:bg-blue-500 shadow-sm font-semibold text-white'>Post Ep</Link>
                <Link to={`/create/release`} className='flex text-center justify-center items-center p-[2px] border rounded-lg px-2 w-[120px] h-[35px] max-sm:w-[80px] max-sm:text-[12px] cursor-pointer bg-zinc-400 hover:bg-blue-600 shadow-sm font-semibold text-white'>Release New</Link>
              </div>
            )}
          </div>
        </div>
        <div className={`w-full max-sm:min-h-[600px] max-sm:mb-10 h-[90%] rounded-2xl border overflow-x-auto scrollbar-thin ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-zinc-100"}`}>
          <div>
            <div className={`flex w-full h-full flex-row flex-wrap gap-4 overflow-x-auto scrollbar-hide p-2`}>
              {loading ? (
                <SkeletonLoader />
              ) : (
                movies.length ? (
                  movies.map((item, index) => (
                    <Link
                      to={`/movies/${item.Type === 'Single Film' ? 'film' : 'movie'}/${item.id}`}
                      className={`mb-4 cursor-pointer w-[120px] ${index === 0 ? 'pl-15' : ''} ${index === movies.length - 1 ? 'pr-15' : ''}`}
                      key={item.id}
                    >
                      <div className={`h-[180px] w-[120px] shadow-lg rounded-xl hover:shadow-xl mb-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-zinc-200"}`}>
                        <img src={item.CoverImage} alt='Movie Cover' className='h-[180px] w-[120px] hover:h-[190px] hover:w-[130px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
                      </div>
                      <div>
                        <div className='font-semibold line-clamp-1 hover:text-blue-500'>{item.MovieTitle}</div>
                        <div className='text-[12px] text-slate-400 hover:text-green-400'>{item.Type}</div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div className='flex flex-col w-full h-full items-center justify-center'>
                    <div className='mt-[100px] max-sm:mt-10 flex-shrink-0'>
                      <img src={logo2} className='w-[250px] shrink-0' />
                    </div>
                    <div className='text-center'>{userData?.displayName} doesn't have any movie released yet!</div>
                  </div>
                )
              )}

            </div>
          </div>
        </div>
      </div>
      {/* User Profile */}
      <div className={`w-[30%] max-sm:w-full h-full gap-2 p-2 flex flex-col items-center rounded-2xl border ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white"}`}>
        <div className='flex gap-2 w-full h-full flex-col items-center justify-between'>
          <div className='h-[40%] flex flex-col w-full items-center justify-center'>
            <div className='h-[80%] w-full flex items-center justify-center'>
              <img src={userData?.img} className='h-[100px] w-[100px] justify-center items-center border rounded-full shrink-0 bg-zinc-300 object-cover' />
            </div>
            <Link to={`/edit-profile`} className='text-center text-blue-500 bg-white cursor-pointer border-spacing-4 rounded-xl border-blue-500 hover:bg-blue-500 hover:text-white hover:font-bold border-2 px-2 w-fit mt-[-30px]'>Edit</Link>
            <div className='gap-1 flex justify-center items-center'>
              <div className='h-[20%] flex w-full'>{userData?.displayName}</div>
              {userData?.verification === "verified" && (
                <div className='text-[13px] font-semibold text-center mt-[20px]'>{darkMode ? <MdOutlineVerified /> : <MdVerified color='blue' />}</div>
              )}
            </div>
            <div className='text-[12px] font-semibold text-center text-gray-300'>{userData?.status}</div>
            <div className='text-center'>@{userData?.username || 'username'}</div>
          </div>
          <div className='flex flex-col gap-2 w-full h-[65%]'>
            <div className={`flex flex-col rounded-xl border h-auto w-full p-2 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-zinc-100"}`}>
              <div className='text-[12px] font-semibold text-slate-400'>Biography</div>
              <div className='text-[14px]'>
                {userData?.bio ? (
                  <>
                    <div className={`overflow-hidden ${!isExpanded && 'line-clamp-2'}`}>
                      {userData?.bio}
                    </div>
                    {userData?.bio.split(' ').length > 20 && (
                      <button onClick={toggleExpand} className="text-blue-500 mt-1">
                        {isExpanded ? 'View less' : 'View more'}
                      </button>
                    )}
                  </>
                ) : (
                  'No Bio'
                )}
              </div>
            </div>
            <div className={`flex flex-col rounded-xl border h-[50%] w-full p-2 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-zinc-100"}`}>
              <div>{userData?.email || 'email'}</div>
              <div>{userData?.phone || 'phone'}</div>
              <div>{userData?.address || 'address'}</div>
              <div>{userData?.country || 'country'}</div>
            </div>
            <div className={`flex items-center justify-between rounded-xl border h-[20%] w-full p-2 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-zinc-100"}`}>
              <div>{userData?.socials || 'Socials'}</div>
              <div
                onClick={handleShareClick}
                className='text-center flex gap-2 rounded-md justify-center items-center p-[2px] px-2 w-[120px] h-fit max-sm:w-[100px] max-sm:text-[12px] cursor-pointer bg-green-500 shadow-sm font-semibold text-white'
              >
                <div>Share</div> 
                <AiOutlineShareAlt size={24} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkeletonLoader = () => {
  const { darkMode } = useContext(DarkModeContext);

  const skeletons = [
    {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
  ];

  return (
    <div className='flex flex-row flex-wrap gap-4 overflow-x-auto scrollbar-hide'>
      {skeletons.map((_, index) => (
        <div key={index} className='my-4 cursor-pointer w-[120px]'>
          <div className={`h-[180px] w-[120px] animate-pulse shadow-lg rounded-xl hover:shadow-xl my-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-zinc-300 animate-pulse"}`}>
          </div>
          <div>
            <div className='font-semibold line-clamp-1 hover:text-blue-500'></div>
            <div className='text-[12px] text-slate-400 hover:text-green-400'></div>
          </div>
        </div>
      ))}
    </div>
  );
};

const create = [
  {
    title: 'Create a post',
    description: 'Weekly posts',
    route: '/create/post'
  },
  {
    title: 'Create a new release',
    description: 'For new films and movies',
    route: '/create/release'
  },
];

export default Profile;