import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db, auth, storage } from "../firebase";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPlayer from 'react-player';
import { DarkModeContext } from '../context/darkModeContext';
import { downloadIcon } from '../assets';
import { MdVerified } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";

const EditFilm = () => {
  const userId = auth.currentUser.uid;
  const { darkMode } = useContext(DarkModeContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [movie, setMovie] = useState(null);
  const [progress, setProgress] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formData, setFormData] = useState({
    MovieTitle: '',
    movieCountry: '',
    Type: '',
    Genres: [],
    Duration: '',
    Description: '',
    Trailer: '',
    BannerImage: '',
    ProducerName: '',
    ProducerImageUrl: '',
    ProducerVerificationStatus: '',
    DownloadLink: '',
    CoverImage: ''
  });
  const [trailerSource, setTrailerSource] = useState('');
  const [trailerURL, setTrailerURL] = useState('');
  const [bannerProgress, setBannerProgress] = useState(null);


  useEffect(() => {
    const fetchMovie = async () => {
      try {
        const movieDocRef = doc(db, 'movies', id);
        const movieDocSnapshot = await getDoc(movieDocRef);
        if (movieDocSnapshot.exists()) {
          const movieData = movieDocSnapshot.data();
          setMovie(movieData);
          setFormData({
            MovieTitle: movieData.MovieTitle,
            movieCountry: movieData.movieCountry,
            Type: movieData.Type,
            Genres: movieData.Genres,
            Duration: movieData.Duration,
            Description: movieData.Description,
            Trailer: movieData.Trailer,
            BannerImage: movieData.BannerImage,
            ProducerName: movieData.ProducerName,
            ProducerImageUrl: movieData.ProducerImageUrl,
            ProducerVerificationStatus: movieData.ProducerVerificationStatus,
            DownloadLink: movieData.DownloadLink,
            CoverImage: movieData.CoverImage
          });
        } else {
          console.error('Movie not found.');
        }
      } catch (error) {
        console.error('Error fetching movie:', error);
      }
    };

    fetchMovie();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleMovieCoverUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    if (!file.type.startsWith('image/')) {
      console.error('Selected file is not an image');
      return;
    }

    if (!formData.MovieTitle) {
      console.error('Please enter the movie title before uploading the cover image');
      return;
    }

    try {
      const fileName = `${formData.MovieTitle}_${Date.now()}_${file.name}`;
      const storageRef = ref(storage, `coverImages/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          if (progress === 100) {
            setSubmitDisabled(false);
          }
        },
        (error) => {
          toast.error('Failed to upload movie cover');
          console.error('Error uploading movie cover:', error.message);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setFormData({ ...formData, CoverImage: downloadURL });
            toast.success('Movie cover uploaded successfully');
          } catch (error) {
            toast.error('Failed to create movie release');
            console.error('Error creating movie release:', error.message);
          }
        }
      );
    } catch (error) {
      console.error('Error compressing image:', error.message);
    }
  };

  const handleBannerUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    if (!file.type.startsWith('image/')) {
      console.error('Selected file is not an image');
      return;
    }

    try {
      const fileName = `${formData.MovieTitle}_banner_${Date.now()}_${file.name}`;
      const storageRef = ref(storage, `bannerImages/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setBannerProgress(progress);
          if (progress === 100) {
            setSubmitDisabled(false);
          }
        },
        (error) => {
          toast.error('Failed to upload banner image');
          console.error('Error uploading banner image:', error.message);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setFormData({ ...formData, BannerImage: downloadURL });
            toast.success('Banner image uploaded successfully');
          } catch (error) {
            toast.error('Failed to create movie release');
            console.error('Error creating movie release:', error.message);
          }
        }
      );
    } catch (error) {
      console.error('Error compressing image:', error.message);
    }
  };

  const handleTrailerUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    if (!file.type.startsWith('video/')) {
      console.error('Selected file is not a video');
      return;
    }

    try {
      const fileName = `${formData.MovieTitle}_${Date.now()}_${file.name}`;
      const storageRef = ref(storage, `trailers/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          if (progress === 100) {
            setSubmitDisabled(false);
          }
        },
        (error) => {
          toast.error('Failed to upload trailer');
          console.error('Error uploading trailer:', error.message);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setFormData({ ...formData, Trailer: downloadURL });
            toast.success('Trailer uploaded successfully');
          } catch (error) {
            toast.error('Failed to upload trailer');
            console.error('Error uploading trailer:', error.message);
          }
        }
      );
    } catch (error) {
      console.error('Error uploading trailer:', error.message);
    }
  };

  const handleTrailerURLChange = (e) => {
    setTrailerURL(e.target.value);
    setFormData({ ...formData, Trailer: e.target.value });
  };

  const handleUpdate = async () => {
    if (movie && movie.ProducerID === userId) {
      try {
        // Create an object with only the changed fields
        const updatedData = Object.keys(formData).reduce((acc, key) => {
          if (formData[key] !== movie[key]) {
            acc[key] = formData[key];
          }
          return acc;
        }, {});
  
        if (Object.keys(updatedData).length === 0) {
          toast.warn("No changes to update.");
          return;
        }
  
        const movieDocRef = doc(db, 'movies', id);
        await setDoc(movieDocRef, updatedData, { merge: true });
        toast.success("Movie updated successfully!");
        navigate(`/movies/film/${id}`);
      } catch (error) {
        toast.error("Failed to update movie.");
      }
    } else {
      toast.error("You are not authorized to edit this movie.");
    }
  };

  return (
    <div>
      {movie ? (
        <div>
          <div>
            <div className={`rounded-2xl shadow-xl h-[200px] flex justify-between p-5 px-10 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white"}`}>
              <div className='flex justify-between gap-5'>
                <img
                  src={formData.CoverImage}
                  alt='Movie Cover Page'
                  title='Movie Cover Page'
                  className='bg-zinc-400 w-[120px] rounded-2xl'
                />
                <div className=''>
                  <input
                    type='text'
                    name='MovieTitle'
                    placeholder="Movie Title"
                    value={formData.MovieTitle}
                    onChange={handleInputChange}
                    className='font-bold text-[18px] pb-1'
                  />
                  <div className="flex flex-col items-start m-1">
                    <input
                      type="file"
                      id="movieCoverUpload"
                      accept="image/*"
                      onChange={handleMovieCoverUpload}
                      className="bg-none"
                    />
                  </div>
                  <div className='flex gap-1'>
                    <input
                      type='text'
                      name='movieCountry'
                      value={formData.movieCountry}
                      onChange={handleInputChange}
                      placeholder="Country"
                      className='text-[14px] w-[100px] text-slate-500 font-medium'
                    />
                    <input
                      type='text'
                      name='Type'
                      placeholder="Type, eg Single Film / Season"
                      value={formData.Type}
                      onChange={handleInputChange}
                      className='text-[14px] text-slate-600 font-medium'
                    />
                  </div>
                  {formData.Genres.length > 0 ? (
                    formData.Genres.map((genre, index) => (
                      <input
                        key={index}
                        type='text'
                        placeholder="Genre"
                        name={`Genres[${index}]`}
                        value={genre}
                        onChange={(e) => {
                          const newGenres = [...formData.Genres];
                          newGenres[index] = e.target.value;
                          setFormData({ ...formData, Genres: newGenres });
                        }}
                        className='text-[14px] w-[100px]'
                      />
                    ))
                  ) : (
                    <div className='text-[14px]'>Genre</div>
                  )}
                  <input
                    type='text'
                    name='Duration'
                    placeholder="Duration"
                    value={formData.Duration}
                    onChange={handleInputChange}
                    className='text-[14px] w-[100px]'
                  />
                </div>
              </div>
              <div className='flex justify-between gap-8'>
                <div>
                  {formData.Trailer ? (
                    <>
                      <ReactPlayer
                        light={true}
                        controls={true}
                        url={formData.Trailer}
                        height="140px"
                        width="260px"
                        className='border-2'
                      />
                      <div className='text-[12px] text-slate-400 font-semibold text-center'>Trailer</div>
                    </>
                  ) : (null)}
                </div>
                <div className='w-[2px] bg-gray-200 h-full'></div>
                <div className='items-center flex flex-col flex-shrink-0'>
                  <img
                    src={formData.ProducerImageUrl || 'https://firebasestorage.googleapis.com/v0/b/trood-app-c5eae.appspot.com/o/1712773008995iTrood.png?alt=media&token=34f6279d-1425-4016-a57b-83a12115c27d'}
                    alt='Producer Image'
                    title='Producer Image'
                    className='h-[80px] w-[80px] m-2 shrink-0 rounded-full bg-zinc-100'
                  />
                  <div className='pt-2'>
                    <div className='gap-1 flex justify-center items-center'>
                      <div
                        className='text-[13px] font-semibold text-center'
                      >
                        {formData.ProducerName}
                      </div>
                      {formData.ProducerVerificationStatus === "verified" && (
                        <div className='text-[13px] font-semibold text-center'>{darkMode ? <MdOutlineVerified /> : <MdVerified color='blue' />}</div>
                      )}
                    </div>
                    <div className='text-[12px] font-semibold text-center text-gray-300'>Producer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='m-2 flex flex-col items-center justify-center'>
            <h2 className='ml-2 font-bold text-slate-400'>Edit</h2>
            <textarea
              name='Description'
              placeholder="Description"
              value={formData.Description}
              onChange={handleInputChange}
              className='m-2 w-full p-2 rounded-xl resize-none shadow-md'
            />
            <div className='m-2'>
              <div className='flex flex-col'>
                <label className='text-[14px] font-semibold text-center'>Trailer</label>
                <div className='flex gap-4'>
                  <label>
                    <input
                      type='radio'
                      name='trailerSource'
                      value='file'
                      checked={trailerSource === 'file'}
                      onChange={() => setTrailerSource('file')}
                      className='mx-2'
                    />
                    Upload File
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='trailerSource'
                      value='url'
                      checked={trailerSource === 'url'}
                      onChange={() => setTrailerSource('url')}
                      className='mx-2'
                    />
                    Link URL
                  </label>
                </div>
                {trailerSource === 'file' && (
                  <input
                    type='file'
                    accept='video/*'
                    onChange={handleTrailerUpload}
                    className='my-2'
                  />
                )}
                {trailerSource === 'url' && (
                  <input
                    type='text'
                    placeholder='Paste trailer URL here'
                    value={trailerURL}
                    onChange={handleTrailerURLChange}
                    className='m-2 w-full p-2 rounded-xl shadow-md'
                  />
                )}
              </div>
            </div>
            <div className='flex flex-col w-full justify-center items-center'>
              <div className='text-[14px] mt-2 font-semibold text-center'>Banner Image</div>
              <input
                type='file'
                accept='image/*'
                onChange={handleBannerUpload}
                className='mb-2'
              />
              {bannerProgress !== null && (
                <div>
                  <progress value={bannerProgress} max="100" className="max-md:w-full w-[280px] mt-4"></progress>
                </div>
              )}
              {formData.BannerImage && (
                <div>
                  <img src={formData.BannerImage} alt='Banner' className='max-md:w-full w-[280px] mt-2' />
                </div>
              )}
            </div>
            <div className='m-2'>
              <div className={`m-2 flex p-2 justify-between items-center w-[315px] h-[80px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin resize-none shadow-md ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode"}`}>
                <div className='flex gap-1 items-center'>
                  <img src={formData.CoverImage} className='h-[50px] w-[35px] m-2 shrink-0 rounded-md bg-zinc-200' />
                  <div>
                    <p className='text-[14px] font-semibold'>{formData.MovieTitle}</p>
                    <p className='text-[12px]'>{formData.Duration}</p>
                  </div>
                </div>
                <a href={formData.DownloadLink} target='_blank' className='flex flex-col gap-1'>
                  <div className='h-[40px] w-[40px] justify-center items-center flex bg-zinc-100 hover:bg-zinc-200 rounded-full border'>
                    <img src={downloadIcon} className='h-[20px] object-contain' />
                  </div>
                  <p className='text-[10px] font-semibold'>Download</p>
                </a>
              </div>
              {progress !== null && (
                <progress value={progress} max="100" className="w-full mt-4"></progress>
              )}
            </div>
          </div>
          {movie && movie.ProducerID === userId && (
            <div className='flex w-full h-[100px] gap-4 justify-center items-center'>
              <button onClick={handleUpdate} className='text-center text-green-500 cursor-pointer border-spacing-4 rounded-xl border-green-500 hover:bg-green-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>Update</button>
            </div>
          )}
        </div>
      ) : (
        <div>
          Loading...
        </div>
      )}
    </div>
  );
};

export default EditFilm;